import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../user-management/users-list/core/QueryRequestProvider'
import {useListView} from '../../core/ListViewProvider'

const UsersListSearchComponent = ({onSearch}) => {
  const {updateState} = useQueryRequest()
  const {setCandidatesData} = useListView()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (debouncedSearchTerm?.trim()) {
        onSearch(debouncedSearchTerm)
      }
    }
  }

  const validationSchema = Yup.object({
    country: Yup.string().required('Country is required'),
    jobTitle: Yup.string().required('Keyword is required'),
    location: Yup.string(),
    keywordsExclude: Yup.string(),
    education: Yup.string(),
    currentEmployer: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      country: '',
      jobTitle: '',
      location: '',
      keywordsExclude: '',
      education: '',
      currentEmployer: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload={...values,query:values.jobTitle}
      onSearch(payload)
    },
  })

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({search: debouncedSearchTerm, ...initialQueryState})
    }
  }, [debouncedSearchTerm])

  return (
    <form onSubmit={formik.handleSubmit} className='card-title'>
      <div className='row'>
        {/* Country Dropdown */}
        <div className='col-md-4 form-group'>
          <label>Country</label>
          <select
            name='country'
            className={`form-control form-control-solid ${
              formik.touched.country && formik.errors.country ? 'is-invalid' : ''
            }`}
            onChange={formik.handleChange}
            value={formik.values.country}
          >
            <option value='' label='Select country' />
            <option value='United States' label='United States' />
            <option value='Canada' label='Canada' />
            <option value='Australia' label='Australia' />
            <option value='Pakistan' label='Pakistan' />
            <option value='Global' label='Global' />
          </select>
          {formik.touched.country && formik.errors.country ? (
            <div className='invalid-feedback'>{formik.errors.country}</div>
          ) : null}
        </div>

        {/* Job Title */}
        <div className='col-md-4 form-group'>
          <label>Keyword</label>
          <input
            type='text'
            name='jobTitle'
            className={`form-control form-control-solid ${
              formik.touched.jobTitle && formik.errors.jobTitle ? 'is-invalid' : ''
            }`}
            placeholder='Enter job title'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.jobTitle}
          />
          {formik.touched.jobTitle && formik.errors.jobTitle ? (
            <div className='invalid-feedback'>{formik.errors.jobTitle}</div>
          ) : null}
        </div>

        {/* Location or keywords to include */}
        <div className='col-md-4 form-group'>
          <label>Location or keywords to include</label>
          <input
            type='text'
            name='location'
            className='form-control form-control-solid'
            placeholder='E.g. New York'
            onChange={formik.handleChange}
            value={formik.values.location}
          />
        </div>
      </div>

      <div className='row mt-4'>
        {/* Keywords to exclude */}
        <div className='col-md-4 form-group'>
          <label>Keywords to exclude</label>
          <input
            type='text'
            name='keywordsExclude'
            className='form-control form-control-solid'
            placeholder='E.g. Intern'
            onChange={formik.handleChange}
            value={formik.values.keywordsExclude}
          />
        </div>

        {/* Education Dropdown */}
        <div className='col-md-4 form-group'>
          <label>Education</label>
          <select
            name='education'
            className='form-control form-control-solid'
            onChange={formik.handleChange}
            value={formik.values.education}
          >
            <option value='' label='Select education' />
            <option value='All candidates' label='All candidates' />
            <option value='Bachelors' label='Bachelors' />
            <option value='Masters' label='Masters' />
            <option value='Doctoral Degree' label='Doctoral Degree' />
            {/* Add other education levels */}
          </select>
        </div>

        {/* Current Employer */}
        <div className='col-md-4 form-group'>
          <label>Current Employer</label>
          <input
            type='text'
            name='currentEmployer'
            className='form-control form-control-solid'
            placeholder='E.g. Google'
            onChange={formik.handleChange}
            value={formik.values.currentEmployer}
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className='d-flex justify-content-end mt-4'>
        <button type='submit' className='btn btn-primary'>
          Find the right Talent
        </button>
      </div>
    </form>
  )
}

export {UsersListSearchComponent}
