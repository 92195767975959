import {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import * as Yup from 'yup'
import {
  createHiringEvent,
  fetchUploadedResume,
  generateResumeQuestions,
  saveResumeQuestions,
} from '../core/_requests'
import {useFormik} from 'formik'
import toast from 'react-hot-toast'
import moment from 'moment'

const UserEditModalForm = () => {
  const {setIsCreateHiringEventModalOpen, setUpdateDocumentState} = useListView()
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [questions, setQuestions] = useState<string[]>([])
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [uploadedResume, setUploadedResumes] = useState([])
  const [savedQuestionsId, setSavedQuestionsId] = useState('')

  useEffect(() => {
    // handleFetchUploadedResume()
  }, [])

  const handleUploadBatchResume = async (e) => {
    e.preventDefault()
    console.log('selectedFiles', selectedFiles)
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    setSelectedFiles(files)
  }
  const openFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const handleFetchUploadedResume = async () => {
    try {
      const id = localStorage.getItem('user_id')
      const response = await fetchUploadedResume(id)
      setUploadedResumes(response?.data?.resumes ?? [])
    } catch (err) {
      toast.error('Something went wrong, please contact support')
    } finally {
    }
  }
  const handleGenerateQuestions = async () => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          if (i === 0) formData.append('resume', selectedFiles[i])
        }
      }
      const response = await generateResumeQuestions(formData)
      if (response.status === 200) {
        setQuestions(response.data.data)
        setStep(2)
        toast.success('Questions have been generated!')
        setIsLoading(false)
      }
    } catch (err) {
      toast.error('Something Went wrong, Please contact support')
    } finally {
      setIsLoading(false)
    }
  }
  const handleCreateEvent = async (payload: any) => {
    try {
      setIsLoading(true)
      const response = await createHiringEvent(payload)
      if (response.data) {
        toast.success('Hiring Event has been created!')
        setIsLoading(false)
        setUpdateDocumentState(true)
        setIsCreateHiringEventModalOpen(false)
      }
    } catch (err) {
      toast.error('Something Went wrong, Please contact support')
    } finally {
      setIsLoading(false)
    }
  }
  const handleSaveQuestions = async (payload: any) => {
    try {
      setIsLoading(true)
      const payload = {
        questions_set: questions,
        email: 'test@test.com',
        job_title: 'default',
      }
      const response = await saveResumeQuestions(payload)
      if (response.status === 200) {
        setSavedQuestionsId(response.data.data.id)
        toast.success('Questions have been saved successfully')
        setStep(3)
        setIsLoading(false)
      }
    } catch (err) {
      toast.error('Something Went wrong, Please contact support')
    } finally {
      setIsLoading(false)
    }
  }

  const FirstStepProcess = () => {
    // console.log(uploadedResume)
    return (
      <div>
        <h1>First Step:</h1>
        <select
          disabled={uploadedResume.length === 0}
          className='form-select'
          aria-label='Default select example'
        >
          <option selected>Select a resume</option>
          {uploadedResume.map((resume: any, index) => {
            return <option value={resume.filename}>{resume.filename}</option>
          })}
        </select>

        <div className='d-flex flex-column align-items-center justify-content-center mt-4 mb-4'>
          <span className='' data-kt-users-modal-action='submit'>
            <span className='fs-2'>OR</span>
          </span>
        </div>

        <h3 className='text-center'>Please Upload A Resume</h3>

        <div className='d-flex flex-column align-items-center justify-content-center mt-4 mb-4'>
          <button
            onClick={openFileSelect}
            className='btn btn-primary btn-lg'
            // data-kt-users-modal-action='submit'
          >
            <span className='fs-2'>+</span>
          </button>
        </div>

        <input
          ref={fileInputRef}
          type='file'
          style={{display: 'none'}}
          onChange={handleFileChange}
        />

        <div className='mb-4'>
          {selectedFiles && (
            <div className='mt-4'>
              <h5>Selected Files:</h5>
              <ul>
                {Array.from(selectedFiles).map((file, index) => (
                  <li key={index}>
                    <span>
                      <i className='bi bi-file-pdf'></i>
                    </span>
                    {file.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className='text-left pt-5'>
          <button
            disabled={!selectedFiles || isLoading}
            onClick={handleGenerateQuestions}
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
          >
            {isLoading ? 'Generating Questions' : 'Generate Questions'}
          </button>
        </div>
      </div>
    )
  }
  const SecondStepProcess = () => {
    const formik = useFormik({
      initialValues: {
        newQuestion: '',
      },
      validationSchema: Yup.object({
        newQuestion: Yup.string().required('*Question is required'),
      }),
      onSubmit: async (values, {setSubmitting}) => {
        setSubmitting(true)
        setQuestions([...questions, values.newQuestion])
      },
    })
    return (
      <div>
        <h1 className='mb-1'>Second Step:</h1>
        <ul className='list-unstyled'>
          {questions.map((question, index) => (
            <li className='mb-2' key={index}>
              <b>{index + 1}-</b>
              {question}
            </li>
          ))}
        </ul>
        <form onSubmit={formik.handleSubmit}>
          <div className='text-left pt-2 mb-1'>
            {formik.touched.newQuestion && formik.errors.newQuestion && (
              <div className='text-danger'>{formik.errors.newQuestion}</div>
            )}
          </div>
          <div className='d-flex align-items-center mb-2'>
            <input
              type='text'
              name='newQuestion'
              placeholder='Enter a new question'
              className='form-control form-control-solid flex-grow-1 mr-2'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newQuestion}
            />
            <button
              disabled={formik.isSubmitting}
              type='submit'
              data-kt-users-modal-action='submit'
              className='btn btn-primary bg-black'
            >
              +
            </button>
          </div>
        </form>
        <div>
          <button
            disabled={isLoading}
            onClick={handleSaveQuestions}
            className='btn mt-2 btn-primary'
          >
            Save and Next
          </button>
        </div>
      </div>
    )
  }
  const ThirdStepProcess = () => {
    const validationSchema = Yup.object({
      expiration_date: Yup.date().required('Date is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      notes: Yup.string().required('Notes are required'),
      title: Yup.string().required('Title is required'),
    })
    const formik = useFormik({
      initialValues: {
        expiration_date: '',
        email: '',
        notes: '',
        title: '',
      },
      validationSchema: validationSchema,
      onSubmit: async (values, {setSubmitting}) => {
        setSubmitting(true)
        if (!savedQuestionsId) return
        const date = new Date(values.expiration_date)
        const dateInUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        const expiration_date = dateInUTC.toISOString().substring(0, 10)
        const payload = {
          expiration_date: moment.utc(expiration_date).endOf('day').toISOString(),
          questions_id: savedQuestionsId,
          candidate_email: values.email.toLocaleLowerCase(),
          title: values.title,
          notes: values.notes,
          resume_id: '',
          job_id: '',
        }
        // console.log(payload)
        await handleCreateEvent(payload)
      },
    })

    return (
      <div>
        <h1>Third Step:</h1>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label className='required fw-bold fs-6 mb-2'>Title</label>
            <input
              type='text'
              name='title'
              placeholder='Title'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='error'>{formik.errors.title}</div>
            )}
          </div>

          <div>
            <label className='required fw-bold fs-6 mb-2'>Notes</label>
            <input
              type='textArea'
              name='notes'
              placeholder='Notes'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notes}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='error'>{formik.errors.notes}</div>
            )}
          </div>

          <div>
            <label className='required fw-bold fs-6 mb-2'>Date</label>
            <input
              type='date'
              id='expiration_date'
              name='expiration_date'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.expiration_date}
            />
            {formik.touched.expiration_date && formik.errors.expiration_date && (
              <div className='error'>{formik.errors.expiration_date}</div>
            )}
          </div>

          <div>
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            <input
              type='text'
              name='email'
              placeholder='Email'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='error'>{formik.errors.email}</div>
            )}
          </div>

          <div className='text-left pt-5'>
            <button
              disabled={formik.isSubmitting}
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
            >
              Create Hiring Event
            </button>
          </div>
        </form>
      </div>
    )
  }

  return (
    <>
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7'
        id='kt_modal_add_user_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        data-kt-scroll-offset='300px'
      >
        {step === 1 && <FirstStepProcess />}
        {step === 2 && <SecondStepProcess />}
        {step === 3 && <ThirdStepProcess />}
      </div>
    </>
  )
}

export {UserEditModalForm}
