import {ID, KTCardBody} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

type candidate = {
  id?: number
  title?: string
  about?: string
  platform?: string
  description?: string
  location?: string
  name?: string
  social_provider?: string
  url?: string
}

type Props = {
  candidate: candidate
}

const Card = ({candidate}: Props) => {
  const {setSelectedId, setSelectedCandidate} = useListView()
  const handleDetailsClick = (candidate) => {
    setSelectedCandidate(candidate)
  }

  return (
    <KTCardBody className='py-4'>
      <div className='card shadow mb-2 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{candidate.name}</h3>
          </div>
          <div className='card-title'>
            {candidate?.social_provider === 'google' ? (
              <a target="_blank" href={candidate?.url}>

              <i className='bi bi-google' style={{fontSize: '2rem', color: '#DB4437'}}></i>
              </a>
            ) : (
              <i className='bi bi-linkedin' style={{fontSize: '2rem', color: '#0073B1'}}></i>
            )}
          </div>
        </div>

        <div id='' className='collapse show'>
          <div className='card-body border-top '>
            <div className={'mb-4'}>
              <div className='fs-6 fw-bolder mb-1'>Headline</div>
              <div className='fw-bold text-gray-600'>{candidate.description}</div>
            </div>

            {candidate.about && (
              <div className={'mb-4'}>
                <div className='fw-bold text-gray-600'>{candidate.about}</div>
              </div>
            )}
            {candidate.location && (
              <div className='d-flex flex-wrap align-items-center'>
                <div className={'mb-4'}>
                  <div className='fs-6 fw-bolder mb-1'>Location</div>
                  <div className='fw-bold text-gray-600'>
                    <i className='bi bi-geo-alt'></i>
                    {candidate.location}
                  </div>
                </div>
              </div>
            )}

            {!(candidate?.social_provider === 'google') && (
              <div className=' d-flex justify-content-end'>
                <button
                  onClick={() => handleDetailsClick(candidate)}
                  className='btn btn-primary px-6 align-self-center text-nowrap '
                >
                  Details
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </KTCardBody>
  )
}
export {Card}
