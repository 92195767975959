import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {CardsWidget20} from '../../../_metronic/partials/widgets'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {CardsWidget5} from '../../../_metronic/partials/widgets/_new/cards/CardsWidget5'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'

const RoleWrapper = () => {
  const intl = useIntl()
  const {user} = useAuth0()
  const navigate = useNavigate()
  const rolesArray = [
    {
      id: 'rol_XInXNTmPb3eJ9I7A',
      titile: 'Manager',
    },
    {
      id: 'rol_AYfvkmIUe3U9Amjy',
      title: 'Candidate',
    },
  ]
  useEffect(() => {
    if (user?.role?.length > 0) {
      navigate("/dashboard")
    }
  }, [user])
  
  const handleSubmitRole = async (id: string) => {
    try {
      const payload = {
        user_id: user?.user_id ?? null,
        role_id: id,
        name: user?.name ?? '',
        domain: 'connectai.ai',
        email: user?.email ?? '',
      }
      const response = await axios.post('http://localhost:8002/v1/roles', payload)
      navigate("/dashboard")
      window.location.reload()
    } catch (err: any) {
      alert(`something went wrng: ${err.message}`)
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <div style={{width: '50%', height: '50%'}}>
        <h1 className='d-flex justify-content-center fs-1 font-weight-bold'>
          Please select a role to continue
        </h1>
        <div className='d-flex justify-content-center'>
          <CardsWidget5
            onClick={() => handleSubmitRole('rol_XInXNTmPb3eJ9I7A')}
            className='h-md-50 mb-5 mb-xl-10 cursor-pointer '
            heading='Manager Role'
            id='rol_XInXNTmPb3eJ9I7A'
            description='Hire talent and make your life easier'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget5
            onClick={() => handleSubmitRole('rol_AYfvkmIUe3U9Amjy')}
            className='h-md-50 mb-5 mb-xl-10 cursor-pointer'
            heading='Candidate Role'
            id='rol_AYfvkmIUe3U9Amjy'
            description='Get hired smoothly with swift process'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        {/* <button className='d-flex justify-content-center'>Logout</button> */}
      </div>
    </div>
  )
}

export default RoleWrapper
