import {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Props} from '../../../../../../_metronic/partials'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import axios from 'axios'
import {fetchUploadedResume, uploadBatchResume} from '../core/_requests'
import toast from 'react-hot-toast'
import {useAuth0} from '@auth0/auth0-react'

// type Props = {
//   isUserLoading: boolean
//   user: User
// }

const createJobSchema = Yup.object().shape({
  title: Yup.string().required('Job Title is required'),
  company_description: Yup.string().required('Company description is required'),
  experience: Yup.string().required('Number of experience is required'),
  skills: Yup.string().required('Job Skills is required'),
  job_description: Yup.string(),
})

const UserEditModalForm = () => {
  const {setSelectedId, setHiringEventsData,setIsBatchUploadModalOpen} = useListView()
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleUploadBatchResume = async (e: any) => {
    try {
      setIsLoading(true)
      e.preventDefault()
      const formData = new FormData()
      if (selectedFiles && selectedFiles.length > 0) {
        const filesArray = Array.from(selectedFiles)
        const _userid = localStorage.getItem('user_id')
        if (_userid) {
          formData.append('user_id', _userid)
          for (const file of filesArray) {
            formData.append('files', file)
          }
        }
      }
      const response = await uploadBatchResume(formData)
      if (response.data.success) {
        toast.success('Your Files have been uploaded!')
        setIsBatchUploadModalOpen(false)
      }
    } catch (err) {
      toast.error('Something went wrong, please contact support')
    } finally {
      setIsLoading(false)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    setSelectedFiles(files)
  }

  const openFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <>
      {/* <form
        id='kt_modal_add_user_form'
        className='form'
        onSubmit={(e)=>handleUploadBatchResume}
        noValidate
      > */}
      <div
        className='d-flex flex-column scroll-y me-n7 pe-7'
        id='kt_modal_add_user_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        data-kt-scroll-offset='300px'
      >
        <h3 className='text-center'>Select Resumes For Batch Upload</h3>

        <div className='d-flex flex-column align-items-center justify-content-center mt-4 mb-4'>
          <button
            onClick={openFileSelect}
            className='btn btn-primary btn-lg'
            data-kt-users-modal-action='submit'
          >
            <span className='fs-2'>+</span>
          </button>
        </div>

        <input
          ref={fileInputRef}
          type='file'
          style={{display: 'none'}}
          onChange={handleFileChange}
          multiple
          accept="application/pdf"
        />
      </div>

      <div className='mb-4'>
        {selectedFiles && (
          <div className='mt-4'>
            <h5>Selected Files:</h5>
            <ul>
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>
                  <span>
                    <i className='bi bi-file-pdf'></i>
                  </span>
                  {file.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className=''>
        <button
          type='submit'
          onClick={handleUploadBatchResume}
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          disabled={!selectedFiles || isLoading}
        >
          <span className='indicator-label'>{isLoading ? 'UPLOADING' : 'UPLOAD'}</span>
        </button>
      </div>
      {/* </form> */}
    </>
  )
}

export {UserEditModalForm}
