import {_mockCandidateData} from '../core/_mockData'
import {Card} from '../components/card/card'
import {useListView} from '../core/ListViewProvider'

const CandidateCard = () => {
  const {candidatesData, loader, setLoader} = useListView()
  return (
    <>
      {candidatesData.map((candidate, index) => {
        console.log("candidate==>",candidate)
        return <Card key={index} candidate={candidate}></Card>
      })}
    </>
  )
}
export {CandidateCard}
