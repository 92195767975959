import {CandidateListToolbar} from './CandidateListToolbar'
import {UsersListGrouping} from './CandidateListGrouping'
import {UsersListSearchComponent} from './CandidateListSearchComponent'
import {fetchScraperJobStatus, searchCandidates} from '../../core/_requests'
import {useQueryRequest} from '../../../../user-management/users-list/core/QueryRequestProvider'
import {useListView} from '../../core/ListViewProvider'
import toast from 'react-hot-toast'

const CandidateListHeader = () => {
  const {setCandidatesData, setLoader} = useListView()
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
  const checkJobStatusRecursively = async (jobId: string, timeoutMs: number): Promise<string> => {
    const startTimestamp = Date.now()
    try {
      while (true) {
        const jobStatus = await checkJobStatus(jobId)
        if (jobStatus === 'completed') {
          return 'completed'
        }
        if (Date.now() - startTimestamp >= timeoutMs) {
          return 'timeout'
        } else {
          await delay(30 * 1000)
        }
      }
    } catch (error) {
      toast.error('Error checking job status')
      return 'error'
    }
  }

  const handleSearch = async (payload: any) => {
    try {
      setLoader(true)
      setCandidatesData([])
      const response = await searchCandidates(payload)
      if (response.status === 200) {
        if (response?.data?.social_provider === 'linkedin') {
          setCandidatesData(response?.data?.results)
          toast.success(
            `${response?.data?.results?.length} Candidate(s) were fetched successfully!`
          )
          setLoader(false)
        } else {
          toast.success(`Job is scheduled successfully!`)
        }
      }
    } catch (err) {
      toast.error('Something went wrong, please contact support')
      setLoader(false)
    }
  }

  const isJobRunning = (jobId: string, jobStatusResponse: any): boolean => {
    const runningJobs = jobStatusResponse?.running || []
    return runningJobs.some((job: any) => job.id === jobId)
  }
  const isJobPending = (jobId: string, jobStatusResponse: any): boolean => {
    const pendingJobs = jobStatusResponse?.pending || []
    return pendingJobs.some((job: any) => job.id === jobId)
  }
  const checkJobStatus = async (jobId: string) => {
    try {
      const jobStatusResponse = await fetchScraperJobStatus()
      const isRunning = isJobRunning(jobId, jobStatusResponse.data)
      const isPending = isJobPending(jobId, jobStatusResponse.data)
      if (isRunning) return 'running'
      if (isPending) return 'running'
      else return 'completed'
    } catch (error) {
      toast.error('Error checking job status:')
    }
  }

  return (
    <div className='m-4 border-0 pt-2 '>
      <UsersListSearchComponent onSearch={handleSearch} />
      {/* <div className='card-toolbar'>{<CandidateListToolbar />}</div> */}
    </div>
  )
}

export {CandidateListHeader}
