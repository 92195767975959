import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {getUserByToken} from '../core/_requests'
import {useAuth} from '../core/Auth'
import { AuthModel } from '../core/_models'

const OAuthCallback = () => {
  const {saveAuth, setCurrentUser, logout} = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const auth = urlParams.get('access_token')
        if (auth) {
          const authObject: AuthModel = { access_token: auth }
          saveAuth(authObject)
          const {data} = await getUserByToken(auth)
          const user = data['data']
          setCurrentUser(user)
        } else {
          logout()
          navigate("/auth")
        }
      } catch (error) {
        logout()
        navigate("/auth")
      }
    }
    fetchToken()
  }, [])

  return <div></div>
}

export default OAuthCallback
