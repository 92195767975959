import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {type} from 'os'

const CandidateDetailForm = () => {
  const {selectedCandidate} = useListView()
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-4'>
            <label className='required fw-bold fs-6 '>{selectedCandidate?.name}</label>
            <div className={'mb-2'}>
              {/* <label className='fs-8 mb-4'>Software Engineer</label> */}
            </div>

            <div className={'mb-4'}>
              <div className='fw-bold text-gray-600'>{selectedCandidate?.about}</div>
            </div>
          </div>

          <div className='fv-row mb-4'>
            <label className='required fw-bold fs-6 mb-2'>Experience</label>
            {selectedCandidate?.experience.map((candidate: any, index) => {
              return (
                <div key={index} className={'mb-4'}>
                  <div className='fw-bold text-gray-600'>{candidate.organisation_profile}</div>
                  <div className='fw-bold text-gray-600'>{candidate.description}</div>
                  <div className='fw-bold text-gray-600'>{candidate.start_time}</div>
                  <div className={'mb-4'}>
                    <div className='fw-bold text-gray-600'>
                      <i className='bi bi-geo-alt'></i>
                      {candidate.location}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className='fv-row mb-4'>
            <label className='required fw-bold fs-6 mb-2'>Education</label>
            {selectedCandidate?.education.map((candidate: any, index) => {
              return (
                <div key={index} className={'mb-4'}>
                  <div className='fw-bold text-gray-600'>{candidate?.course_details}</div>
                </div>
              )
            })}
          </div>

          <div className='fv-row mb-4'>
            <label className='required fw-bold fs-6 mb-2'>Location</label>
            <div className='d-flex flex-wrap align-items-center'>
              <div className={'mb-4'}>
                <div className='fw-bold text-gray-600'>
                  <i className='bi bi-geo-alt'></i>
                  {selectedCandidate?.location}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {CandidateDetailForm}
