import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from '../../user-management/users-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../user-management/users-list/core/QueryResponseProvider'
import {KTCard} from '../../../../../_metronic/helpers'
import {CandidateListHeader} from './components/header/CanidateListHeader'
import {CandidateCard} from './candidate-card/candidate-card'
import {useEffect, useState} from 'react'
import {CandidateDetailModal} from './candidate-detail-modal/CandidateDetailModal'

const CandidateList = () => {
  const {selectedCandidate} = useListView()
  const {candidatesData, setCandidatesData, loader, setLoader} = useListView()

  useEffect(() => {
    const wsUrl = `wss://js.connectaidevelopment.com/ws/${0}`
    const socket = new WebSocket(wsUrl)
    socket.addEventListener('open', function (event) {
      console.log('WebSocket connection established.')
    })
    socket.onmessage = (event) => {
      // console.log('Message from server:', event.data)
      const newMessage = event.data
      if (newMessage) {
        console.log('Message from server:', newMessage)
        setCandidatesData((prevData: any) => [...prevData, JSON.parse(newMessage)])
      }
    }
    socket.addEventListener('error', function (event) {
      console.error('WebSocket error:', event)
    })
    socket.addEventListener('close', function (event) {
      console.log('WebSocket connection closed.')
    })
  }, [])
  return (
    <>
      <KTCard>
        <CandidateListHeader />
      </KTCard>

      <div className='pt-4'>
        <KTCard>
          <CandidateCard />
        </KTCard>
      </div>

      <div className='pt-4'>
        {loader && (
          <div className='d-flex justify-content-center align-items-center'>
            <div className='spinner-border' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        )}
      </div>
      {selectedCandidate !== null && selectedCandidate != undefined && <CandidateDetailModal />}
    </>
  )
}

const CandidateSearchWrapper = () => (
  <ListViewProvider>
    <CandidateList />
  </ListViewProvider>
)

export {CandidateSearchWrapper}
