import axios from 'axios'
import {AuthModel, UserModel} from './_models'

// const API_URL = process.env.REACT_APP_API_URL
const API_URL = 'https://connectaidevelopment.com/auth/api/v1'
// const API_URL = 'http://localhost:8000/auth/api/v1'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user-profile-by-token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const SIGNIN_WITH_GOOGLE = `${API_URL}/auth/google`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {email, password})
}

// Server should return success
export function register(email: string, first_name: string, last_name: string, password: string) {
  return axios.post(REGISTER_URL, {email, first_name, last_name, password})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    access_token: token,
    token_type: "bearer",
  })
}
