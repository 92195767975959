import axios, {AxiosResponse} from 'axios'

const RESUME_SCAN_API_URL = 'https://connectaidevelopment.com/questions/api/v1'
const generate_questions = `${RESUME_SCAN_API_URL}/generate-ai-questions`
const save_questions = `${RESUME_SCAN_API_URL}/questions`

const HIRING_EVENT_API_URL = 'https://connectaidevelopment.com/hiring/api/v1'
const create_events = `${HIRING_EVENT_API_URL}/hiring-events`
const fetch_events = `${HIRING_EVENT_API_URL}/hiring-events`
const delete_job = `${HIRING_EVENT_API_URL}/hiring-events`
const update_job = `${HIRING_EVENT_API_URL}/hiring-events`


const RESUME_BATCH_UPLOAD_API_URL = 'https://connectaidevelopment.com'
const batch_upload = `${RESUME_BATCH_UPLOAD_API_URL}/upload`
const fetch_resumes = `${RESUME_BATCH_UPLOAD_API_URL}/resumes`


const generateResumeQuestions = (payload: any): Promise<any> => {
  const response = axios.post(generate_questions, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}
const saveResumeQuestions = (payload: any): Promise<any> => {
  const response = axios.post(save_questions, payload)
  return response
}
const uploadBatchResume = (payload: any): Promise<any> => {
  const response = axios.post(batch_upload, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}
const fetchUploadedResume = (id: any): Promise<any> => {
  const response = axios.get(`${fetch_resumes}/${id}`,)
  return response
}
const createHiringEvent = (payload: any): Promise<any> => {
  const response = axios.post(create_events, payload)
  return response
}
const fetchAllEvents = (): Promise<any> => {
  const response = axios.get(fetch_events)
  return response
}
const updateJob = (jobDetails: any): Promise<any> => {
  const response = axios.post(update_job, jobDetails)
  return response
}
const deleteJob = (payload: any): Promise<any> => {
  const response = axios.delete(`${delete_job}/${payload.id}`)
  return response
}

export {generateResumeQuestions,saveResumeQuestions, fetchAllEvents, deleteJob,createHiringEvent,uploadBatchResume,fetchUploadedResume}
