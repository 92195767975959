import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { HiringListingWrapper } from './candidate-list/HiringListing'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Hiring Events',
    path: '/apps/hiring/hiring-events',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const HiringEventPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='hiring-events'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Hiring Events</PageTitle>
              <HiringListingWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/hiring/hiring-events' />} />
    </Routes>
  )
}

export default HiringEventPage
