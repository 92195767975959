import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { CandidateSearchWrapper } from './candidate-list/CandidateList'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Candidate Search',
    path: '/apps/candidates/candidate-search',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CandidateSearchPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='candidate-search'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Talent Acquisition</PageTitle>
              <CandidateSearchWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/candidates/candidate-search' />} />
    </Routes>
  )
}

export default CandidateSearchPage
