import {FC, useState, createContext, useContext, useMemo} from 'react'
import {ID, WithChildren, initialListView} from '../../../../../../_metronic/helpers'
import {candidateProps, initialCandidateProps} from './_models'

const ListViewContext = createContext<candidateProps>(initialCandidateProps)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selectedId, setSelectedId] = useState<ID>(initialCandidateProps.selectedId)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(initialCandidateProps.isDetailModalOpen)
  const [isBatchUploadModalOpen, setIsBatchUploadModalOpen] = useState<boolean>(false)
  const [isCreateHiringEventModalOpen, setIsCreateHiringEventModalOpen] = useState<boolean>(false)
  const [hiringEventsData, setHiringEventsData] = useState<any>([])
  const [updateDocumentState,setUpdateDocumentState]=useState<boolean>(true)
  const [loader,setLoader]=useState<boolean>(false)

  return (
    <ListViewContext.Provider
      value={{
        isDetailModalOpen,
        setIsDetailModalOpen,
        selectedId,
        setSelectedId,
        hiringEventsData,
        setHiringEventsData,
        isBatchUploadModalOpen,
        setIsBatchUploadModalOpen,
        isCreateHiringEventModalOpen,
        setIsCreateHiringEventModalOpen,
        updateDocumentState,
        setUpdateDocumentState,
        loader,
        setLoader
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
