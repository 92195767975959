import {Dispatch, SetStateAction} from 'react'
import {ID} from '../../../../../../_metronic/helpers'

export type candidateProps = {
  selectedId: ID
  setSelectedId: Dispatch<SetStateAction<ID>>
  isDetailModalOpen: boolean
  setIsDetailModalOpen: Dispatch<SetStateAction<boolean>>
  candidatesData: []
  setCandidatesData: Dispatch<SetStateAction<any>>
  selectedCandidate: any
  setSelectedCandidate: Dispatch<SetStateAction<any>>
  loader:boolean
  setLoader:Dispatch<SetStateAction<any>>
  
}

export const initialCandidateProps: candidateProps = {
  selectedId: null,
  setSelectedId: () => {},
  isDetailModalOpen: false,
  setIsDetailModalOpen: () => {},
  candidatesData: [],
  setCandidatesData: () => {},
  selectedCandidate: {},
  setSelectedCandidate: () => {},
  loader:false,
  setLoader: ()=>{}
}
