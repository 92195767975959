import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from '../../user-management/users-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../user-management/users-list/core/QueryResponseProvider'
import {KTCard} from '../../../../../_metronic/helpers'
import {JobListHeader} from './components/header/JobListHeader'
import {useEffect, useState} from 'react'
import {UsersTable} from '../../user-management/users-list/table/UsersTable'
import {BatchUploadModal} from './batch-upload-modal/BackUploadModal'
import JobListtingTable from './table/JobListingTable'
import {fetchAllEvents} from './core/_requests'
import {CreateEditHiringEventModal} from './hiring-event-modal/HiringEventModal'
import toast from 'react-hot-toast'

const HiringList = () => {
  const {
    selectedId,
    hiringEventsData,
    setHiringEventsData,
    isBatchUploadModalOpen,
    isCreateHiringEventModalOpen,
    updateDocumentState,
    setUpdateDocumentState,
    setLoader
  } = useListView()

  const handleFetchJobsData = async () => {
    try {
      setLoader(true)
      const response = await fetchAllEvents ()
      const message= `${Array.isArray(response.data.data) ? response.data.data.length : 0} document(s) were fetched successfully`
      setHiringEventsData(response.data.data)
      setUpdateDocumentState(false)
      setLoader(false)
      toast.success(message)
    } catch (err: any) {
      setLoader(false)
      toast.error('Something Went wrong, Please contact support')
    }
  }

  useEffect(() => {
    if(updateDocumentState){
      handleFetchJobsData()
    }
  }, [updateDocumentState])

  return (
    <>
      <KTCard>
        <JobListHeader />
      </KTCard>

      <div className='pt-4'>
        <KTCard>
          <JobListtingTable />
        </KTCard>
      </div>
      {isBatchUploadModalOpen && <BatchUploadModal />}
      {isCreateHiringEventModalOpen && <CreateEditHiringEventModal />}
    </>
  )
}

const HiringListingWrapper = () => (
  <ListViewProvider>
    <HiringList />
  </ListViewProvider>
)

export {HiringListingWrapper}
