import {useRef, useState} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './CandidateListFilter'

const CandidateListToolbar = () => {
  const {setSelectedId, setIsBatchUploadModalOpen,setIsCreateHiringEventModalOpen} = useListView()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)

  const openBatchUploadModal = () => {
    setSelectedId(null)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    setSelectedFiles(files)
  }

  const openFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <button
          type='button'
          className='btn btn-light-primary me-3'
          // className='btn btn-primary'
          onClick={() => setIsCreateHiringEventModalOpen(true)}
        >
          <KTIcon iconName='plus' className='fs-2' />
          Create Hiring
        </button>
        {/* <button
          // type='button'
          // className='btn btn-primary'
          type='button' className='btn btn-light-primary me-3'
          onClick={() => setIsBatchUploadModalOpen(true)}
        >
          <KTIcon iconName='plus' className='fs-2' />
          Batch Upload
        </button> */}
      </div>
    </>
  )
}

export {CandidateListToolbar}
