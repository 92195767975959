// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import moment from 'moment'


const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
  //   accessor: 'id',
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Title' className='min-w-125px' />,
    accessor: 'title',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Expiration Date' className='min-w-125px' />,
    accessor: 'expiration_date',
    Cell: ({ value }) => moment.utc(value).format('LLLL'),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='email' className='min-w-125px' />,
    accessor: 'candidate_email',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Notes' className='min-w-125px' />,
    accessor: 'notes',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    accessor: 'status',
    Cell: ({ value }) => value.toLocaleUpperCase(),
  },

 
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
