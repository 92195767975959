import axios, {AxiosResponse} from 'axios'

const CANDIDATE_SEARCH_API_URL = 'https://cs.connectaidevelopment.com/api/v1'
const fetch_scraper_jobs_details = `${CANDIDATE_SEARCH_API_URL}/job-status/linkedin_scraper/listjobs`
const search_candidates = `${CANDIDATE_SEARCH_API_URL}/search/`



const searchCandidates = (payload: any): Promise<any> => {
  const response = axios.post(search_candidates, payload)
  return response
}
const fetchScraperJobStatus = (): Promise<any> => {
  const response = axios.get(fetch_scraper_jobs_details)
  return response
}

export {searchCandidates, fetchScraperJobStatus}
