import {Dispatch, SetStateAction} from 'react'
import {ID} from '../../../../../../_metronic/helpers'
import {boolean} from 'yup'

export type candidateProps = {
  selectedId: ID
  setSelectedId: Dispatch<SetStateAction<ID>>
  isDetailModalOpen: boolean
  setIsDetailModalOpen: Dispatch<SetStateAction<boolean>>
  hiringEventsData: []
  setHiringEventsData: Dispatch<SetStateAction<any>>
  isBatchUploadModalOpen: boolean
  setIsBatchUploadModalOpen: Dispatch<SetStateAction<any>>
  isCreateHiringEventModalOpen: boolean
  setIsCreateHiringEventModalOpen: Dispatch<SetStateAction<any>>
  updateDocumentState:boolean
  setUpdateDocumentState:Dispatch<SetStateAction<any>>
  loader:boolean
  setLoader:Dispatch<SetStateAction<any>>
}

export const initialCandidateProps: candidateProps = {
  selectedId: undefined,
  setSelectedId: () => {},
  isDetailModalOpen: false,
  setIsDetailModalOpen: () => {},
  hiringEventsData: [],
  setHiringEventsData: () => {},
  isBatchUploadModalOpen: false,
  setIsBatchUploadModalOpen: () => {},
  isCreateHiringEventModalOpen: false,
  setIsCreateHiringEventModalOpen: () => {},
  updateDocumentState:true,
  setUpdateDocumentState: ()=>{},
  loader:true,
  setLoader: ()=>{}
}
